const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const authCookieSettings =
  process.env.REACT_APP_AUTH_USE_COOKIE === 'true'
    ? {
        cookieStorage: {
          domain: process.env.REACT_APP_AUTH_COOKIE_DOMAIN,
          path: process.env.REACT_APP_AUTH_COOKIE_PATH,
          expires: Number(process.env.REACT_APP_AUTH_COOKIE_EXPIRES),
          sameSite: process.env.REACT_APP_AUTH_COOKIE_SAME_SITE,
          secure: process.env.REACT_APP_AUTH_COOKIE_SECURE === 'true',
        },
      }
    : {};

export default {
  Auth: {
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    ...authCookieSettings,
    oauth: {
      domain: process.env.REACT_APP_AUTH_OAUTH_DOMAIN,
      scope: process.env.REACT_APP_AUTH_OAUTH_SCOPE.split(',').map(s => s.trim()),
      redirectSignIn: isLocalhost ? 'http://localhost:3000' : process.env.REACT_APP_DASHBOARD_URL,
      redirectSignOut: isLocalhost
        ? 'http://localhost:3000/login'
        : `${process.env.REACT_APP_DASHBOARD_URL}/login`,
      responseType: 'code',
    },
  },
};
