import { Box } from '@mui/material';
import { Copy, AnchorLink } from '../components';

export function NotFoundPage() {
  return (
    <Box>
      <Copy>That link has no power here</Copy>
      <AnchorLink path="/">
        <Copy>Go Back Home</Copy>
      </AnchorLink>
    </Box>
  );
}
