import { Button, Stack } from '@mui/material';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

export function SSOButtons() {
  return (
    <Stack width="100%" gap={2}>
      <Button
        fullWidth
        startIcon={<img src="/google.svg" alt="google-icon" />}
        sx={{
          border: '1px solid #DADCE0',
          borderRadius: '4px',
          py: 1,
          '> .MuiButton-startIcon': { position: 'absolute', left: 0, ml: 1 },
        }}
        onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
      >
        Sign in with google
      </Button>
    </Stack>
  );
}
