import { useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { Outlet } from 'react-router';
import { Box } from '@mui/material';
import { useSitesAtom } from '../atoms';
import { loadAllSites } from '../services';
import { Header } from '../components';

export const PageLayout = () => {
  const [sites, setSites] = useSitesAtom();
  const { result } = useAsync(loadAllSites, []);

  useEffect(() => {
    if (result && !sites.length) {
      setSites(result);
    }
  }, [result, setSites, sites.length]);

  return (
    <>
      <Header />
      <Box component="main" sx={{ p: 2 }}>
        <Outlet />
      </Box>
    </>
  );
};
