import { atom, useAtom } from 'jotai';
import { Env, Site, VersionRaw } from '../types';

const sitesAtom = atom<Site[]>([]);

const siteConfigAtom = atom<Record<string, object>>({});
const siteVersionsAtom = atom<Record<string, Record<Env, VersionRaw>>>({});

export const useSitesAtom = () => useAtom(sitesAtom);
export const useSiteAtom = (slug: string) => {
  const [sites] = useSitesAtom();
  return sites.find(s => s.slug === slug);
};
export const useSiteConfigAtom = () => useAtom(siteConfigAtom);
export const useSiteVersionsAtom = () => useAtom(siteVersionsAtom);
