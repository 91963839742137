"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiError = void 0;
class ApiError extends Error {
    constructor(err) {
        var _a, _b;
        super((err === null || err === void 0 ? void 0 : err.message) || 'Unknown error');
        this.status = 0;
        this.code = '';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        }
        if (err === null || err === void 0 ? void 0 : err.response) {
            if ((_a = err.response.data) === null || _a === void 0 ? void 0 : _a.error) {
                this.message = err.response.data.error;
            }
            if (err.response.status) {
                this.status = err.response.status;
            }
            if ((_b = err.response.data) === null || _b === void 0 ? void 0 : _b.code) {
                this.code = err.response.data.code;
            }
        }
    }
    toString(asObj) {
        const obj = {
            status: this.status,
            code: this.code,
            message: this.message,
        };
        return asObj ? obj : JSON.stringify(obj);
    }
}
exports.ApiError = ApiError;
