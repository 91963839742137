import { ElementType, ReactNode, useMemo } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useTranslation, TFunction } from 'react-i18next';

export interface CopyProps<C extends ElementType = 'span'> extends TypographyProps {
  /**
   * Locale Id to lookup or the text to render if `noTranslate` is true
   */
  locale?: string;
  /**
   * Children to render. This will superceed `locale` if provided. This should be used
   * if needing to render more than just a single line of text (e.g. columns)
   */
  children?: ReactNode | ((options: { t: TFunction }) => JSX.Element);
  /**
   * Data to be provided to the localization tool
   */
  data?: object;
  /**
   * If the value provided in `locale` shouldn't be translated, e.g. when displaying API
   * error messages or the copy to be shown isn't in the locale mapping.
   */
  noTranslate?: boolean;
  /**
   * Optional setting to change the rendered native element
   */
  component?: C;
}

/**
 * Component to render copy using the localization system.
 */
export function Copy<C extends ElementType = 'span'>({
  locale = '',
  children: childrenProp,
  data,
  noTranslate,
  ...props
}: CopyProps<C>) {
  const { t } = useTranslation();
  const children = useMemo(
    () => (typeof childrenProp === 'function' ? childrenProp({ t }) : childrenProp),
    [childrenProp, t]
  );

  const html = useMemo(() => (noTranslate ? locale : t(locale, data)), [
    noTranslate,
    locale,
    data,
    t,
  ]);

  return <Typography {...props}>{children || html}</Typography>;
}
