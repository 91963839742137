import { Box, Container, Grid, Typography } from '@mui/material';
import { SSOButtons } from '../components';

export function Login() {
  return (
    <Grid container height="100%">
      <Grid component="section" item xs={12} md={6} sx={{ height: '100%', overflow: 'auto' }}>
        <Container
          maxWidth="xs"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" fontWeight="bold" py={2}>
            Welcome
          </Typography>
          <Typography variant="h5" fontWeight="bold" py={2}>
            Surfaces Dashboard
          </Typography>
          <SSOButtons />
        </Container>
      </Grid>
      <Grid component="aside" item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box
          sx={{
            backgroundImage: 'url(/layout.png)',
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </Grid>
  );
}
