import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Box,
} from '@mui/material';
import { ExpandMore, Settings } from '@mui/icons-material';
import { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router';
import { useAsyncCallback } from 'react-async-hook';
import { Site } from '../../../types';
import { Heading2 } from '../../elements';
import { getSiteData } from '../../../services';
import { AppItemVersion, Props as AppItemVersionProps } from './Version';

type Props = {
  open?: boolean;
  onClick?(): void;
  site: Site;
  save?: AppItemVersionProps['save'];
};

export const AppItem = memo(({ open, onClick, site, save }: Props) => {
  const [hideLogo, setHideLogo] = useState(false);
  const navigate = useNavigate();
  const { result: dataTest, execute: fetchTest, loading: loadingTest } = useAsyncCallback(
    getSiteData
  );
  const { result: dataStaging, execute: fetchStaging, loading: loadingStaging } = useAsyncCallback(
    getSiteData
  );
  const {
    result: dataProduction,
    execute: fetchProduction,
    loading: loadingProduction,
  } = useAsyncCallback(getSiteData);

  useEffect(() => {
    if (open) {
      if (!dataTest) {
        fetchTest(site.slug, 'test');
      }
      if (!dataStaging) {
        fetchStaging(site.slug, 'staging');
      }
      if (!dataProduction) {
        fetchProduction(site.slug, 'production');
      }
    }
  }, [dataProduction, dataStaging, dataTest, fetchProduction, fetchStaging, fetchTest, open, site]);

  return (
    <Accordion
      expanded={open}
      onChange={onClick}
      sx={{ my: 1, backgroundColor: 'grey.50' }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container alignItems="center" spacing={4}>
          <Grid alignItems="center" item xs={1}>
            {/* TODO pull logo from config: templateData.logo.dark */}
            <Box justifyContent="center" alignItems="center" sx={{ maxWidth: 60, maxHeight: 50 }}>
              {!hideLogo && (
                <img
                  src={`${site.url.test}/images/logo.png`}
                  alt="logo"
                  loading="lazy"
                  style={{ width: '100%' }}
                  onError={() => setHideLogo(true)}
                />
              )}
            </Box>
          </Grid>
          <Grid item flex={1}>
            <Heading2 variant="h5">{site.name}</Heading2>
          </Grid>
          <Grid item>
            <IconButton onClick={() => navigate(`/${site.slug}`)}>
              <Settings />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={4}>
            <AppItemVersion
              url={dataTest?.siteUrl}
              env="test"
              version={dataTest?.version}
              loading={loadingTest}
              save={save}
            />
          </Grid>
          <Grid item xs={4}>
            <AppItemVersion
              url={dataStaging?.siteUrl}
              env="staging"
              version={dataStaging?.version}
              loading={loadingStaging}
              save={save}
            />
          </Grid>
          <Grid item xs={4}>
            <AppItemVersion
              url={dataProduction?.siteUrl}
              env="production"
              version={dataProduction?.version}
              loading={loadingProduction}
              save={save}
              sx={{ border: 0 }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
});
