import { Grid, IconButton, Box, Skeleton } from '@mui/material';
import { Assignment, OpenInNew } from '@mui/icons-material';
import { formatDistance } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Body1, AnchorLink } from '../../elements';
import { WithSx } from '../../../constants';
import { Env, VersionRaw } from '../../../types';
import { LineItem } from './LineItem';

export type Props = WithSx & {
  version?: VersionRaw;
  url?: string;
  env: Env;
  loading?: boolean;
  save?(version?: VersionRaw): void;
};

export const AppItemVersion = ({ url = '', env, version, sx, loading, save }: Props) => {
  const sinceLastDeploy = useMemo(
    () => (version?.date ? formatDistance(Date.parse(version.date), Date.now()) : ''),
    [version]
  );
  const { t } = useTranslation();

  return (
    <Grid container borderRight="1px solid" borderColor="grey.300" px={4} sx={sx}>
      <Grid item xs={8}>
        <AnchorLink path={url} readonly={!url} sx={{ display: 'flex', alignItems: 'center' }}>
          <Body1 locale={`home.${env}`} sx={{ fontWeight: 600 }} />
          {url && <OpenInNew sx={{ height: 14, width: 0, ml: '1em' }} />}
        </AnchorLink>
        <Grid container spacing={2} flexWrap="wrap" sx={{ pt: 3, '& .MuiGrid-item': { pt: 0 } }}>
          <LineItem label="home.app" value={version?.appVersion} loading={loading} />
          <LineItem label="home.surfaces" value={version?.surfacesVersion} loading={loading} />
          <LineItem
            label="home.build"
            value={version?.surfacesBuild?.slice(0, 8)}
            loading={loading}
          />
          <LineItem label="home.dpl" value={version?.data?.toString()} loading={loading} />
          <LineItem label="home.ds" value={version?.sampleProjects?.toString()} loading={loading} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="end"
      >
        {loading ? (
          <Skeleton variant="text" width={50} />
        ) : (
          <Body1 locale={sinceLastDeploy} noTranslate sx={{ fontStyle: 'italic' }} />
        )}
        <Box>
          {loading ? (
            <Skeleton variant="circular">
              <IconButton>
                <Assignment />
              </IconButton>
            </Skeleton>
          ) : (
            <IconButton onClick={() => save?.(version)} title={t('home.copyToClipBoard')}>
              <Assignment />
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
