import { useMemo, useRef, useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { getSitesByVersion } from '../services';
import { useFuzzySearch } from './useFuzzySearch';
import { Env, Site, FilterParams } from '../types';

const filterParamsDefault: FilterParams = {
  environments: [],
  versions: [],
};

function toggleArrayItem(arr: string[], val: string) {
  return arr.includes(val) ? arr.filter(el => el !== val) : [...arr, val];
}

export const useFilteredSites = () => {
  const searchSites = useFuzzySearch();
  const [filterParams, setFilterParams] = useState<FilterParams>(filterParamsDefault);
  const { result, loading, error } = useAsync(getSitesByVersion, [filterParams]);
  const prevSites = useRef<Site[]>([]);

  const sites = useMemo(() => {
    const mappedResult = Array.isArray(result) ? result.map(({ site }) => site) : result;
    return mappedResult
      ? searchSites.filter(site => mappedResult.includes(site.slug))
      : searchSites;
  }, [result, searchSites]);

  function toggleEnvironmentFilter(val: Env) {
    setFilterParams(prevParams => ({
      ...prevParams,
      environments: toggleArrayItem(prevParams.environments, val),
    }));
  }

  function toggleVersionFilter(val: string) {
    setFilterParams(prevParams => ({
      ...prevParams,
      versions: toggleArrayItem(prevParams.versions, val),
    }));
  }

  useEffect(() => {
    if (!loading) {
      prevSites.current = sites;
    }
  }, [sites, loading]);

  return {
    sitesData: {
      sites: loading ? prevSites.current : sites,
      loading,
      error,
    },
    filterParams,
    toggleEnvironmentFilter,
    toggleVersionFilter,
  };
};
