import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const SiteDetailsPage = () => (
  <Grid container flexDirection="column">
    <Grid item flex={1}>
      <Outlet />
    </Grid>
  </Grid>
);
