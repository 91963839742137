import { useAtom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

const buildInformationAtom = atomWithReset('');

export const useBuildInformationAtom = () => {
  const [buildInformation, setBuildInformation] = useAtom(buildInformationAtom);
  return {
    buildInformation,
    setBuildInformation,
    clearBuildInformation: () => setBuildInformation(RESET),
  };
};
