// eslint-disable-next-line prettier/prettier
import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';

export const ROUTE = {
  home: '/',
  login: '/login',
  layerMap: '/:siteId/layermap',
  config: '/:siteId/config',
  localization: '/:siteId/localization',
};

export const APP_VERSIONS = ['v4.1', 'v4.2', 'v4.3'];
export const APP_ENVIRONMENTS = ['Test', 'Staging', 'Production'];

export enum SUBSCRIPTION {
  BASIC,
  PRO,
}

export type WithSx = { sx?: SxProps<Theme>; className?: string };
export type WithSxKeys<C extends string> = {
  sx?: Partial<Record<C, SxProps<Theme>>>;
  classes?: Partial<Record<C, string>>;
}; 
export type SxArray = Extract<SxProps<Theme>, Array<SxProps>>; 