export type Locale = { code: string; translations: object };

export const EN: Locale = {
  code: 'en',
  translations: {
    home: {
      search: 'Search by name (min 2 characters)',
      re2: 'RE2',
      latestSurfacesVerion: 'Latest Surfaces Version',
      latestDeployments: 'Latest Deployments',
      test: 'Test',
      staging: 'Staging',
      production: 'Production',
      app: 'App',
      surfaces: 'Surfaces',
      build: 'Build',
      dpl: 'DPL',
      ds: 'DS',
      copyToClipboard: 'Copy build to clipboard',
      buildInformation: 'Build Information',
    },
    layerMap: {
      title: 'Layer Map',
      buttonLabel: 'Button Label',
      layerType: 'Layer Type',
      interior: 'Interior Product Type(s)',
      exterior: 'Exterior Product Type(s)',
    },
    general: {
      showAll: 'Show All',
      legend: 'Legend',
      default: 'Default',
      override: 'Override',
      added: 'Added',
      removed: 'Removed',
      key: 'Key',
      value: 'Value',
    },
  },
};
