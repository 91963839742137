import { Skeleton } from '@mui/material';
import { Body1 } from '../../elements';

type Props = {
  label?: string;
  loading?: boolean;
};

export const Label = ({ label, loading }: Props) =>
  loading ? (
    <Skeleton variant="text" />
  ) : (
    <Body1 locale={label} noTranslate sx={{ fontStyle: 'italic' }} />
  );
