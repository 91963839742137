import { useEffect, useMemo } from 'react';
import { Route, Routes, Navigate, useParams, Outlet, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Stack, Box } from '@mui/material';
import { NotFoundPage } from './NotFound';
import { HomePage } from './Home';
import { SiteDetailsPage } from './SiteDetails';
import { LayerMapPage } from './LayerMap';
import { PageLayout } from './Page';
import { ROUTE } from '../constants';
import { Login } from './Login';
import { useAuth } from '../hooks';

function Page() {
  const { id } = useParams();
  return <Navigate replace to={`/${id}/layermap`} />;
}

const ProtectedRoute = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [params] = useSearchParams();
  // Catch Cognito error from URL params.
  const error = useMemo(() => params.get('error_description'), [params]);
  const user = useAuth();
  // Show error snackbar
  useEffect(() => {
    if (error) {
      if (error.includes('domain not valid')) {
        enqueueSnackbar('Email domain is not allowed', { variant: 'error' });
      } else {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  }, [enqueueSnackbar, error]);
  return user ? <Outlet /> : <Navigate replace to={ROUTE.login} />;
};

const PublicOnlyRoute = () => {
  const user = useAuth();
  return !user ? <Outlet /> : <Navigate replace to={ROUTE.home} />;
};

export const AppRoutes = () => (
  <Stack height="100vh">
    <Box flex={1}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<PageLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/:id" element={<SiteDetailsPage />}>
              <Route path="layermap" element={<LayerMapPage />} />
              <Route index element={<Page />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
        <Route path={ROUTE.login} element={<PublicOnlyRoute />}>
          <Route index element={<Login />} />
        </Route>
      </Routes>
    </Box>
  </Stack>
);
