import { Grid } from '@mui/material';
import { Body1 } from '../../elements';
import { Label } from './Label';

type Props = {
  label: string;
  value?: string;
  loading?: boolean;
};

export const LineItem = ({ label, value, loading }: Props) => (
  <>
    <Grid item xs={3}>
      <Body1 locale={label} />
    </Grid>
    <Grid item xs={3} />
    <Grid item xs={6}>
      <Label label={value} loading={loading} />
    </Grid>
  </>
);
