import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

export const loadingAtom = atom(false);

export type LoadingHandlerFn = (fn: () => Promise<void> | void) => Promise<void>;

export const useLoadingAtom = () => {
  const [isLoading, setIsLoading] = useAtom(loadingAtom);

  const handleLoading = useCallback<LoadingHandlerFn>(
    async fn => {
      setIsLoading(true);
      try {
        await fn();
      } catch (error) {
        // ignore
      }
      setIsLoading(false);
    },
    [setIsLoading]
  );

  return {
    isLoading,
    handleLoading,
  };
};
