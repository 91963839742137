import { Box, Dialog, DialogTitle, DialogContent, Grid, Skeleton, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useCallback, useState } from 'react';
import { useBuildInformationAtom } from '../atoms';
import { AppItem, Chip, Copy, Search } from '../components';
import { useFilteredSites } from '../hooks';
import { VersionRaw, Env } from '../types';
import { APP_VERSIONS, APP_ENVIRONMENTS } from '../constants';

export function HomePage() {
  const {
    sitesData,
    toggleEnvironmentFilter,
    toggleVersionFilter,
    filterParams,
  } = useFilteredSites();
  const [selected, setSelected] = useState('');
  const {
    buildInformation,
    setBuildInformation,
    clearBuildInformation,
  } = useBuildInformationAtom();

  const handleItemClick = useCallback(
    (id: string) => {
      setSelected(selected === id ? '' : id);
    },
    [selected]
  );
  const versionToClipboard = useCallback(
    async (version?: VersionRaw) => {
      const items = [
        ['App', version?.appVersion],
        ['Surfaces', version?.surfacesVersion],
        ['Build', version?.surfacesBuild],
        ['DPL', version?.data],
        ['DS', version?.sampleProjects],
        ['Date', version?.date],
      ];
      const str = items.map(pair => pair.join(': ')).join(' / ');

      // This is the preferred method, but only works on https and dashboard is forced to use
      // http until all apps are on AWS, because while deployed on IIS test is using http

      // await window.navigator.clipboard.writeText(str);
      setBuildInformation(str);
    },
    [setBuildInformation]
  );

  const emptySites = new Array(10).fill('placeholder').map((el, i) => `${el}-${i + 1}`);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item flex={1}>
          <Box mb={2}>
            {APP_ENVIRONMENTS.map(environment => (
              <Chip
                label={environment}
                key={environment}
                variant="outlined"
                icon={
                  filterParams.environments.includes(environment.toLowerCase()) ? (
                    <CheckIcon />
                  ) : (
                    undefined
                  )
                }
                onClick={() => toggleEnvironmentFilter(environment.toLowerCase() as Env)}
              />
            ))}
          </Box>
          <Box mb={2}>
            {APP_VERSIONS.map(version => (
              <Chip
                label={version}
                key={version}
                variant="outlined"
                icon={filterParams.versions.includes(version) ? <CheckIcon /> : undefined}
                onClick={() => toggleVersionFilter(version)}
              />
            ))}
          </Box>
          <Box mb={2}>
            <Search />
          </Box>
          {!sitesData.sites.length ? (
            <Box>
              {filterParams.environments.length || filterParams.versions.length ? (
                <Typography variant="body1">No apps found</Typography>
              ) : (
                emptySites.map(key => (
                  // eslint-disable-next-line react/jsx-indent
                  <Skeleton
                    key={key}
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={50}
                    sx={{ my: 4 }}
                  />
                ))
              )}
            </Box>
          ) : (
            <Box sx={{ opacity: sitesData.loading ? 0.5 : 1 }}>
              {sitesData.sites.map(site => (
                // eslint-disable-next-line react/jsx-indent
                <AppItem
                  key={site.slug}
                  open={site.slug === selected}
                  onClick={() => handleItemClick(site.slug)}
                  site={site}
                  save={versionToClipboard}
                />
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog open={!!buildInformation} onClose={() => clearBuildInformation()}>
        <DialogTitle>
          <Copy variant="h3" locale="home.buildInformation" />
        </DialogTitle>
        <DialogContent>
          <code>{buildInformation}</code>
        </DialogContent>
      </Dialog>
    </>
  );
}
