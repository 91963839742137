import FuzzySearch from 'fuzzy-search';
import { useMemo } from 'react';
import { useSearchAtom, useSitesAtom } from '../atoms';

export const useFuzzySearch = () => {
  const [sites] = useSitesAtom();
  const [searchQuery] = useSearchAtom();

  const sitesOrdered = useMemo(
    () =>
      sites.sort((a, b) => {
        if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      }),
    [sites]
  );

  const searcher = useMemo(() => new FuzzySearch(sitesOrdered, ['name', 'slug']), [sitesOrdered]);
  return useMemo(() => (searchQuery.length >= 2 ? searcher.search(searchQuery) : sitesOrdered), [
    searchQuery,
    searcher,
    sitesOrdered,
  ]);
};
