import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { AppRoutes } from './pages';
import { ThemeProvider } from './hooks';
import { Authentication } from './Authentication';
import './locale'; // Init locale

export const App = () => (
  <ThemeProvider>
    <BrowserRouter>
      <CssBaseline />
      <Authentication>
        <SnackbarProvider>
          <AppRoutes />
        </SnackbarProvider>
      </Authentication>
    </BrowserRouter>
  </ThemeProvider>
);
