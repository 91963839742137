import { AppBar, Box, IconButton, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import { AnchorLink } from '../elements';
import { ROUTE } from '../../constants';
import { useAuth } from '../../hooks';
import { UserAccount } from './UserAccount';

export function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useAuth();
  const onSearchButton = () => document.getElementById('search')?.focus();

  return (
    <AppBar
      component="nav"
      position="sticky"
      sx={{ backgroundColor: 'background.paper', color: 'common.black' }}
    >
      <Toolbar sx={{ px: { xs: 2 } }}>
        <Box sx={{ flexGrow: 1 }}>
          <AnchorLink path={ROUTE.home}>
            <img
              src={isMobile ? '/logo-xs.png' : '/logo.png'}
              alt="logo"
              style={{ height: 48, maxWidth: '100%', maxHeight: '100%' }}
            />
          </AnchorLink>
        </Box>
        <Box>
          <IconButton onClick={onSearchButton}>
            <SearchRounded />
          </IconButton>
          {user && <UserAccount user={user} />}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
