import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsyncAbortable } from 'react-async-hook';
import useConstant from 'use-constant';

type AbortableDebouncedFn<R, P extends unknown[]> = (abort: AbortSignal, ...args: P) => Promise<R>;

/**
 * Debounce an async function with a loading state.
 *
 * Based on this example https://www.npmjs.com/package/react-async-hook/v/2.2.1#how-can-i-implement-a-debounced-search-input--autocomplete
 *
 * @param fn Function that will perform the search
 * @param delay Debounce delay in milliseconds
 * @returns Hook used to perform the action
 * @example
 *
 * const Component = () => {
 *  const api = useContext();
 *
 *  const callApi = useCallback(
 *    (abort: AbortSignal, q: string) => api.get('path', { q, abort }),
 *    [api],
 *  );
 *
 *  const { useDebouncedFn } = useDebounced(callApi, 1000); // 1 second delay
 *
 *  const { result, loading, error } = useDebouncedFn(
 *    (execute) => execute(),
 *    [...params for `callApi` sans "abort"]
 *  );
 * }
 */
export const useDebounced = <R, P extends unknown[]>(
  fn: AbortableDebouncedFn<R, P>,
  delay: number
) => {
  const debouncedFn = useConstant(() => AwesomeDebouncePromise(fn, delay));

  const useDebouncedFn = (cb: (execute: () => Promise<R>) => Promise<R>, params: P) =>
    useAsyncAbortable<R, P>((abort, ...args) => cb(() => debouncedFn(abort, ...args)), params);

  return { useDebouncedFn };
};
