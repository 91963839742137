import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '@mui/material';
import { useCallback } from 'react';
import { Body1, Chip } from '../elements';
import { LayerMapRaw } from '../../types';

type Props = {
  data: LayerMapRaw;
};

export const LayerMapTable = ({ data }: Props) => {
  // Logic to include truthy items in the list if not explicitly in the list
  const includeLayerTypeInMappingIfTruthy = useCallback(
    (layerType: string, interior: boolean, chipsArray: string[]) => {
      // if there are items, disconsider logic
      if (chipsArray.length > 0) return chipsArray;

      const mapType = interior ? 'interior' : 'exterior';

      if (data.sceneTypes[layerType]?.includes(mapType)) return [...chipsArray, layerType];
      return chipsArray;
    },
    [data.sceneTypes]
  );

  const getChips = useCallback(
    (key: string, interior?: boolean) => {
      const map =
        data?.layerMap?.[
          interior ? 'interiorLayerProductTypesTranslator' : 'exteriorLayerProductTypesTranslator'
        ];

      return includeLayerTypeInMappingIfTruthy(key, interior ?? false, Object.keys(map[key] || {}));
    },
    [data, includeLayerTypeInMappingIfTruthy]
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Body1 locale="layerMap.buttonLabel" />
            </TableCell>
            <TableCell>
              <Body1 locale="layerMap.layerType" />
            </TableCell>
            <TableCell>
              <Body1 locale="layerMap.interior" />
            </TableCell>
            <TableCell>
              <Body1 locale="layerMap.exterior" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.layerOrder?.map(key => (
            <TableRow key={key}>
              <TableCell>
                <Chip label={<Body1 locale={data.labels[key]} noTranslate />} />
              </TableCell>
              <TableCell>
                <Chip label={<Body1 locale={key} noTranslate />} />
              </TableCell>
              <TableCell>
                <Grid container spacing={2}>
                  {getChips(key, true).map(productType => (
                    <Chip key={productType} label={<Body1 locale={productType} noTranslate />} />
                  ))}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container spacing={2}>
                  {getChips(key).map(productType => (
                    <Chip key={productType} label={<Body1 locale={productType} noTranslate />} />
                  ))}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
