import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
  PaletteColorOptions,
} from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ReactNode } from 'react';
import 'typeface-public-sans';

export const renoworksPrimaryPalette: PaletteColorOptions = {
  50: '#f5f5f5',
  100: '#cadae2',
  200: '#acc0cb',
  300: '#8ca6b5',
  400: '#7492a3',
  500: '#5c7f92',
  600: '#507081',
  700: '#415c6a',
  800: '#334955',
  900: '#22343e',
  main: '#334955',
  dark: '#22343e',
  light: '#7492a3',
};

export const renoworksSecondaryPalette: PaletteColorOptions = {
  50: '#fffee7',
  100: '#fefac3',
  200: '#fdf69b',
  300: '#fbf272',
  400: '#f8ed50',
  500: '#f6e82b',
  600: '#fcdc2d',
  700: '#fbc424',
  800: '#faab19',
  900: '#f88203',
  main: '#faab19',
  dark: '#f88203',
  light: '#f8ed50',
};

const WHITE = '#fff';
const BLACK = '#222f34';

const fontSize = 14; // Same as default. Don't change
const htmlFontSize = 16; // Same as default. Don't change
// This method is available AFTER theme is created. This is same logic so we can
// use as part of making the theme
const pxToRem = (size: number) => `${(size / htmlFontSize) * (fontSize / htmlFontSize)}rem`;

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      common: {
        white: WHITE,
        black: BLACK,
      },
      background: {
        paper: WHITE,
        default: '#f5f5f5',
      },
      primary: renoworksPrimaryPalette,
      secondary: renoworksSecondaryPalette,
      error: {
        main: '#d63e3e',
        contrastText: WHITE,
      },
      success: {
        main: '#61ed77',
      },
      text: {
        primary: BLACK,
        secondary: '#747474',
        disabled: '#aeaeae',
      },
    },
    typography: {
      fontSize,
      htmlFontSize,
      fontFamily: 'public-sans, sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: pxToRem(96),
        letterSpacing: pxToRem(1),
      },
      h2: {
        fontWeight: 700,
        fontSize: pxToRem(60),
        letterSpacing: pxToRem(1),
      },
      h3: {
        fontWeight: 700,
        fontSize: pxToRem(48),
      },
      h4: {
        fontWeight: 700,
        fontSize: pxToRem(34),
        textTransform: 'uppercase',
      },
      h5: {
        fontWeight: 500,
        fontSize: pxToRem(24),
      },
      h6: {
        fontWeight: 500,
        fontSize: pxToRem(20),
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: pxToRem(16),
        textTransform: 'uppercase',
        letterSpacing: pxToRem(1),
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: pxToRem(14),
        textTransform: 'uppercase',
        letterSpacing: pxToRem(1),
      },
      body1: {
        fontSize: pxToRem(16),
      },
      body2: {
        fontSize: pxToRem(14),
      },
      button: {
        fontSize: pxToRem(14),
        textTransform: 'uppercase',
        letterSpacing: pxToRem(1),
      },
      caption: {
        fontSize: pxToRem(12),
      },
      overline: {
        fontWeight: 400,
        fontSize: pxToRem(10),
        letterSpacing: pxToRem(1),
        textTransform: 'uppercase',
      },
    },
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            // This is defaulting to `uppercase`
            textTransform: 'none',
          },
        },
      },
    },
  })
);

const themeCache = createCache({
  key: 'mui',
  prepend: true,
});

type ThemeProviderProps = {
  children: ReactNode;
};

export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <CacheProvider value={themeCache}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </CacheProvider>
  );
}
