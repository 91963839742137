import clientApiFactory, { IClient, ISite } from '@renoworks/client-api';
import axios from 'axios';
import { Env, Site, VersionRaw, LayerMapRaw, SiteVersionPayload, FilterParams } from '../types';

const clientApiTest = clientApiFactory('https://api.test.renoworks.com/');
const clientApiStaging = clientApiFactory('https://api.staging.renoworks.com/');
const clientApiProd = clientApiFactory('https://api.renoworks.com/');

const ignoreSites = [
  // Cuttlefish
  'homeplay',
  'homeplayprime',
  'dreamdesigner',
];

const transformSite = (env: Env, site?: ISite, base?: Site): Site => ({
  name: site?.name ?? '',
  slug: site?.site_id ?? '',
  ...base,
  url: {
    ...base?.url,
    [env]: site?.site_url,
  },
});

const getSitesFromClients = (clients: IClient[], env: Env): Site[] =>
  clients
    // Move sites to top level and transform to internal format
    .flatMap(({ sites }) => (sites || []).map(s => transformSite(env, s)))
    // Filter out certain sites that shouldn't show (e.g. Cuttlefish)
    .filter(site => !site || !ignoreSites.includes(site.slug));

const getSiteVersion = async (url: string) => {
  const endpoint = `${url}/versions.json`;

  const { data } = await axios.get<VersionRaw>(endpoint, {
    params: {
      // Cache bust
      t: Date.now(),
    },
    // TODO This is causing CORS errors preventing file from being loaded
    // Removing this until we can resolve the CORS error
    // headers: {
    //   'Cache-Control': 'no-cache',
    //   Expires: 0,
    // },
  });
  return data;
};

const processSiteUrl = (site_url: string) => {
  if (/^http:/.test(site_url)) {
    return site_url.replace(/^http:/, 'https:');
  }
  return site_url;
};

export const getSiteLayerMap = async (site?: Site) => {
  if (!site?.url.test) {
    return {} as LayerMapRaw;
  }

  const { data } = await axios.get<LayerMapRaw>(`${site.url.test}/dist/mapping.json`);
  return data;
};

/**
 * Load all sites from the test api
 */
export const loadAllSites = async () => {
  const data = await clientApiTest.getClients({ params: { all: true } });
  return getSitesFromClients(data, 'test');
};

/**
 * Get the site url for specific env
 */
export const getSiteUrl = async (site: string, env: Env) => {
  const apiCall = {
    test: () => clientApiTest.getSite(site),
    staging: () => clientApiStaging.getSite(site),
    production: () => clientApiProd.getSite(site),
  }[env];
  const data = await apiCall();
  return processSiteUrl(data.site_url);
};

const SITE_CONFIGS = {
  rwpro: { params: '?contractor=dpl' },
  // Add other site configurations here
} as { [key: string]: { params: string } };

export const getSiteData = async (site: string, env: Env) => {
  // Get the url to link out to
  const baseUrl = await getSiteUrl(site, env);
  // Get version info
  const version = await getSiteVersion(baseUrl);

  // Construct the final URL using the hash map lookup
  const siteConfig = SITE_CONFIGS[site];
  const siteUrl = siteConfig ? `${baseUrl}${siteConfig.params}` : baseUrl;

  return { siteUrl, version };
};

const dashboardApiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_DASHBOARD_API,
});

export const getSitesByVersion = (
  filterParams: FilterParams
): Promise<void | SiteVersionPayload[]> => {
  const { environments, versions } = filterParams;
  const searchParams = new URLSearchParams();

  if (versions.length) {
    const effectiveEnvironments = environments.length
      ? environments
      : ['test', 'staging', 'production'];

    effectiveEnvironments.forEach(environment => {
      searchParams.append(environment, versions.sort().join(','));
    });
  }

  const searchParamsString = searchParams.toString().length ? `?${searchParams.toString()}` : '';
  return searchParamsString
    ? dashboardApiClient
        .get(`/versions${searchParamsString}`)
        .then(res => res.data as SiteVersionPayload[])
    : Promise.resolve();
};
