// eslint-disable-next-line prettier/prettier
import type { Theme } from '@mui/material';
import type { SxProps, SystemStyleObject } from '@mui/system';

type SxArray = Array<boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)>;

export const mergeSx = (...sxs: (SxProps<Theme> | undefined)[]) =>
  sxs.reduce<SxArray>(
    (arr, sx) => {
      if (typeof sx === 'undefined') {
        return arr;
      }
      const sxArr = Array.isArray(sx) ? sx : [sx];
      return [...arr, ...sxArr];
    },
    [] as SxArray,
  );

// Temp function until APIs are implemented
export const pause = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export const toSentance = (camelStr = '', capitalize?: boolean) => {
  const str = camelStr.replace(/([A-Z])/g, ' $1').toLowerCase();
  return capitalize ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : str;
};
