import React, { createContext, useEffect, useState } from 'react';
import { Hub, Auth, Amplify } from 'aws-amplify';
import { User } from './types';
import awsConfigs from './awsConfigs';

let amplifyConfigured = false;

export const UserContext = createContext<User | null>(null);

interface AuthenticationProps {
  children: React.ReactNode;
}

export function Authentication({ children }: Readonly<AuthenticationProps>) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const getUser = async (): Promise<void> => {
    try {
      setLoading(true);
      const userData: User = await Auth.currentAuthenticatedUser();
      setUser(userData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!amplifyConfigured) {
      Amplify.configure(awsConfigs);
      amplifyConfigured = true;
    }
    const unsubscribe = Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser();
          break;
        case 'signOut':
          setUser(null);
          break;
        default:
          break;
      }
    });

    getUser();
    return unsubscribe;
  }, []);

  if (loading) {
    return null;
  }
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
