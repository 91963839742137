import { Skeleton } from '@mui/material';
import { useAsync } from 'react-async-hook';
import { useParams } from 'react-router';
import { useSiteAtom } from '../atoms';
import { LayerMapTable } from '../components';
import { getSiteLayerMap } from '../services';

export const LayerMapPage = () => {
  const { id } = useParams();
  const site = useSiteAtom(id || '');
  const { result, loading } = useAsync(getSiteLayerMap, [site]);

  return (
    <>
      {result && <LayerMapTable data={result} />}
      {loading && <Skeleton variant="rectangular" height={300} width="100%" />}
    </>
  );
};
