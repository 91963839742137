import i18n, { ResourceLanguage, Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import flatten from 'flat';
import { EN } from './en';

const resources = [EN].reduce<Resource>(
  (obj, { code, translations }) => ({
    ...obj,
    [code]: {
      translation: flatten<object, ResourceLanguage>(translations),
    },
  }),
  {} as Resource
);

i18n.use(initReactI18next).init({
  resources,
  lng: EN.code, // Default language
  interpolation: { escapeValue: false },
});

export default i18n;
