import { TextField } from '@mui/material';
import { useState } from 'react';
import { useSearchAtom } from '../../atoms';
import { useDebounced } from '../../hooks';
import { Copy } from '../elements';

export const Search = () => {
  const [, setSearch] = useSearchAtom();
  const [value, setValue] = useState('');

  const { useDebouncedFn } = useDebounced(async (_, v: string) => {
    setSearch(v);
  }, 300);
  useDebouncedFn(
    async execute => {
      execute();
    },
    [value]
  );

  return (
    <TextField
      id="search"
      fullWidth
      value={value}
      onChange={e => setValue(e.target.value)}
      label={<Copy fontSize="inherit" locale="home.search" />}
    />
  );
};
